/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    ol: "ol",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Salcombe Gin Flying Fifteen Southern Area Championships 2024"), "\n", React.createElement(_components.h2, null, "Overview"), "\n", React.createElement(_components.p, null, "The Salcombe Gin Flying Fifteen Southern Area Championships 2024 is an upcoming sailing event that will be held on the weekend of 8th & 9th June 2024. The event is organized by Parkstone Yacht Club and is open for entries. It will take place in Poole Bay and will feature two days of great racing. The championships serve as the first World Qualification event for the 2025 World Championships, which will be held in Weymouth in August."), "\n", React.createElement(_components.h2, null, "Previous Edition"), "\n", React.createElement(_components.p, null, "In a previous edition of the Salcombe Gin Flying Fifteen Southern Championships held at Parkstone Yacht Club, Jeremy Davy and Martin Huett emerged as the winners. The event featured 20 participating boats, and Davy and Huett secured victory in the third race to claim the overall championship win. Richard Lovering and Matt Alvarado finished second overall, while Ian Pinnell and Ian Cadwallader took third place."), "\n", React.createElement(_components.h2, null, "Sponsors"), "\n", React.createElement(_components.p, null, "The event is sponsored by Salcombe Gin, Digital Sailing, Hyde Sails, Pinnell and Bax, Goacher Sails, North Sails, and Ovington Boats."), "\n", React.createElement(_components.h2, null, "Sources"), "\n", React.createElement(_components.p, null, "For more information about the Salcombe Gin Flying Fifteen Southern Area Championships 2024, you can visit the following sources:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sail-world.com/news/271785/Flying-Fifteen-Southern-Championship-entry-opens"
  }, "Sail World - Flying Fifteen Southern Championship entry opens")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bvmsports.com/2024/02/18/flying-fifteen-southern-championship-entry-opens/"
  }, "BVM Sports - Flying Fifteen Southern Championship entry opens")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yachtsandyachting.com/news/250601/Salcombe-Gin-Flying-Fifteen-Southerns-at-Parkstone"
  }, "Yachts and Yachting - Salcombe Gin Flying Fifteen Southerns at Parkstone")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
